<template>
  <div class="row full" id="support">
    <h3 class="subtitle">{{ $t("support.title") }}</h3>
    <div>
      <a
        v-for="(item, index) in supportItems"
        :href="item.link"
        target="_blank"
        rel="noopener noreferrer"
        :key="index"
      >
        <img :src="item.img" alt="{{ item.name }}" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportLinks",
  data() {
    return {
      supportItems: [
        {
          name: "Vanier College",
          link: "https://www.vaniercollege.qc.ca/",
          img: require("@/assets/support/VanierWordmarkRGB.jpg"),
        },
        {
          name: "Canadian Wildlife Federation",
          link: "https://cwf-fcf.org/",
          img: require("@/assets/support/Bilingual-CWFLogo_Stacked.jpg"),
        },
        {
          name: "Colleges and Institutes Canada",
          link: "https://www.collegesinstitutes.ca/",
          img: require("@/assets/support/cican-logo-horizontal-black-en.png"),
        },
        {
          name: "Nature Positive Universities",
          link: "https://www.naturepositiveuniversities.net/",
          img: require("@/assets/support/NPU-original-transparent.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-size: 3rem;
  padding: 4rem 0 0 0;
  text-align: center;
}

#support {
  color: #193c65;
}

#support div {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding-bottom: 1em;
  gap: 3rem;
  padding-top: 3rem;
}

#support div img {
  width: 80%;
  opacity: 1;
}

#support div img:hover {
  opacity: 0.75;
}

@media screen and (min-width: 768px) {
  #support div {
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
}

@media screen and (min-width: 1280px) {
  #support div {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
