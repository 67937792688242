import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

// https://stackoverflow.com/questions/73584321/i18n-in-vue3-with-languange-path-implementation-problem-with-router?rq=1

const langList = ["en", "fr"];
const langRegex = langList.join("|");
// if doamin name is biodivcampus.org default language is french
const defaultLang =
  window.location.hostname === "biodivcampus.org" ? "fr" : "en";
// console.log("defaultLang", defaultLang);
// console.log(window.location.hostname);

const routes = [
  {
    path: "/",
    redirect: `/${defaultLang}`,
  },
  {
    path: `/:lang(${langRegex})`,
    name: "home",
    component: HomeView,
  },
  {
    path: `/:lang(${langRegex})/institutions`,
    name: "institutions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InstitutionsView.vue"),
  },
  {
    path: `/:lang(${langRegex})/ecq-quebec-initiatives`,
    name: "quebec-initiatives",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/QuebecInitiativesView.vue"
      ),
  },
  {
    path: `/:lang(${langRegex})/campus-walks`,
    name: "campus-walks",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CampusWalksView.vue"),
  },
  {
    path: `/:lang(${langRegex})/walk-boxes`,
    name: "walk-boxes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WalkBoxesView.vue"),
  },
  {
    path: `/:lang(${langRegex})/campus-bioblitzes`,
    name: "campus-bioblitzes",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CampusBioblitzesView.vue"
      ),
  },
  {
    path: `/:lang(${langRegex})/biodiversity-toolkit`,
    name: "biodiversity-toolkit",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BiodiversityToolkitView.vue"
      ),
  },
  {
    path: `/:lang(${langRegex})/faq`,
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FaqView.vue"),
  },
  {
    path: `/:lang(${langRegex})/getting-started`,
    name: "getting-started",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GettingStartedView.vue"),
  },
  {
    path: `/:lang(${langRegex})/join`,
    name: "join",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JoinView.vue"),
    // alias: [
    //   `/:lang(${langRegex})/main-project`,
    //   `/:lang(${langRegex})/main-project/`,
    //   `/:lang(${langRegex})/commitments-greenspace/`,
    //   `/:lang(${langRegex})/commitments-indigenous/`,
    //   `/:lang(${langRegex})/commitments-inclusivity/`,
    //   `/:lang(${langRegex})/inscription/`,
    //   `/:lang(${langRegex})/engagements-espaces-verts/`,
    //   `/:lang(${langRegex})/engagements-territoires/`,
    //   `/:lang(${langRegex})/engagements-inclusivite/`,
    // ],
  },
  {
    path: `/:lang(${langRegex})/mission`,
    name: "mission",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MissionView.vue"),
    alias: [`/:lang(${langRegex})/rationale`],
  },
  {
    path: `/:lang(${langRegex})/privacy`,
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyView.vue"),
  },
  {
    path: `/:lang(${langRegex})/projects`,
    name: "projects",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProjectsView.vue"),
    alias: [
      `/:lang(${langRegex})/main-project`,
      `/:lang(${langRegex})/main-project/`,
    ],
  },
  {
    path: `/:lang(${langRegex})/resources`,
    name: "resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResourcesView.vue"),
  },
  {
    path: `/:lang(${langRegex})/team`,
    name: "team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TeamView.vue"),
  },
  // route same pages without lang to redirect to same pages with lang
  {
    path: "/institutions",
    redirect: `/${defaultLang}/institutions`,
  },
  {
    path: "/campus-walks",
    redirect: `/${defaultLang}/campus-walks`,
  },
  {
    path: "/faq",
    redirect: `/${defaultLang}/faq`,
  },
  {
    path: "/getting-started",
    redirect: `/${defaultLang}/getting-started`,
  },
  {
    path: "/join",
    redirect: `/${defaultLang}/join`,
  },
  {
    path: "/rationale",
    redirect: `/${defaultLang}/mission`,
  },
  {
    path: "/mission",
    redirect: `/${defaultLang}/mission`,
  },
  {
    path: "/privacy",
    redirect: `/${defaultLang}/privacy`,
  },
  {
    path: "/main-project",
    redirect: `/${defaultLang}/projects`,
  },
  {
    path: "/resources",
    redirect: `/${defaultLang}/resources`,
  },
  {
    path: "/team",
    redirect: `/${defaultLang}/team`,
  },
  { path: "/:pathMatch(.*)*", redirect: "/en" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
