<template>
  <div class="carousell">
    <carousel :wrap-around="true">
      <slide v-for="(slide, index) in slides" :key="index" :index="index">
        <img :src="slide.src" />
        <div class="slide__content">
          <a :href="slide.link" target="_blank">
            <img src="../assets/cc.svg" alt="CC" />{{ slide.title }}
          </a>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "CarouselWidget",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      slides: [
        {
          title: "Phuentsho - Royal University of Bhutan - Pseudozizeeria maha",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/278362750/large.jpeg",
          link: "https://inaturalist.ca/observations/161058254",
        },
        {
          title: "matthewrettino - Dawson College - Clytus ruricola",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/289317111/large.jpeg",
          link: "https://www.inaturalist.org/observations/167038906",
        },
        // {
        //   title:
        //     "Royal College of Bhutan - Cheirotonus macleayi - Sunil Spakota ",
        //   src: "https://inaturalist-open-data.s3.amazonaws.com/photos/51071663/large.jpeg",
        //   link: "https://www.inaturalist.org/observations/32554339",
        // },
        {
          title:
            "Lukas K. (someplant)- University of Tokyo - Gomphonema acuminatum",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/249306828/large.jpeg",
          link: "https://www.inaturalist.org/observations/145160380",
        },
        {
          title: "Satoyasuyo - University of Tokyo - Plestiodon finitimus",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/272199073/large.jpg",
          link: "https://inaturalist.ca/observations/157425239",
        },
        {
          title: "D. Potter - Cégep Saint-Laurent - Vulpes vulpes",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/282912432/large.jpg",
          link: "https://www.inaturalist.org/observations/163565397",
        },
        {
          title: "Ben P - University of Cape Coast Ghana - Vigna vexillata",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/32063668/large.jpg",
          link: "https://www.inaturalist.org/observations/20790898",
        },
        {
          title: "D. Potter - Cégep Saint-Laurent - Falco sparverius",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/313845894/large.jpg",
          link: "https://inaturalist.ca/observations/180198012",
        },
        {
          title: "eden2411 - McGill University - Anaxyrus americanus",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/320884408/large.jpg",
          link: "https://www.inaturalist.org/observations/183887132",
        },
        {
          title: "andreakoz - Canadore College - Ramariopsis kunzei",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/308579356/large.jpeg",
          link: "https://www.inaturalist.org/observations/177424845",
        },
        {
          title: "D. Potter - Vanier College - Danaus plexippus",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/310111164/large.jpg",
          link: "https://www.inaturalist.org/observations/178237624",
        },
        {
          title: "Dawson Living Campus - Dawson College - Dryocopus pileatus",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/308853518/large.jpg",
          link: "https://www.inaturalist.org/observations/177572438",
        },
        {
          title: "rainaf - McGill University - Tamias striatus",
          src: "https://inaturalist-open-data.s3.amazonaws.com/photos/40489971/large.jpeg",
          link: "https://inaturalist.ca/observations/26095265",
        },
      ],
      // observations: [],
      // observationsList: [
      //   161058254, 167038906, 32554339, 145160380, 157425239, 163565397,
      //   20790898, 180198012, 183887132, 177424845, 178237624, 177572438,
      //   26095265,
      // ],
    };
  },
  // methods: {
  //   fetchINatObservation(obsId) {
  //     let obs = null;
  //     const url = `https://api.inaturalist.org/v1/observations/${obsId}`;
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         obs = data.results[0].photos[0];
  //         console.log(obs.attribution);
  //         console.log(obs.url);
  //         return obs;
  //       });
  //   },
  // },
  // mounted() {
  //   this.observations.forEach((obsId) => {
  //     this.observations = this.fetchINatObservation(obsId);
  //   });
  // },
  // computed: {
  //   observations() {
  //     return this.observationsList.map((obsId) => {
  //       return this.fetchINatObservation(obsId);
  //     });
  //   },
  // },
};
</script>

<style scoped lang="scss">
@import "vue3-carousel/dist/carousel.css";

.carousell {
  // background-color: #193c65;
  padding: 1rem 0;
  .carousel__slide {
    // background-color: #193c65;
    width: 100%;
    height: 60vh;
    // height: 30rem;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 0.5rem;
      @media screen and (min-width: 768px) {
        height: 99%;
        width: auto;
        max-width: 90%;
        object-fit: contain;
      }
      // object-position: top;
      // aspect-ratio: 1/1;
    }
    .slide__content {
      position: absolute;
      bottom: 0%;
      // padding: 0.25rem 0.5rem;
      color: white;
      background-color: #193c65;
      width: 100%;
      font-size: 0.65rem;
      @media screen and (min-width: 768px) {
        bottom: 0.5%;
        right: 0;
        text-align: right;
        width: auto;
        border-radius: 0.5rem 0.5rem 0 0.5rem;
      }
      a {
        color: white;
        text-decoration: none;
        font-weight: 300;
        margin: 0.5rem;
        font-size: 0.75rem;
        display: grid;
        grid-template-columns: auto 1fr;
        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .carousel__pagination {
    padding-inline-start: 0;
    //   .carousel__pagination-button:hover::after,
    //   .carousel__pagination-button--active::after {
    //     background-color: #fff;
    //   }
  }
}
</style>
