<template>
  <div class="row full copyright" id="copyright">
    <p>&copy; 2023 {{ $t("copyright.title") }}</p>
    <a class="top-link hide" href="" id="js-top">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6">
        <path d="M12 6H0l6-6z" />
      </svg>
      <span class="screen-reader-text">{{ $t("back-to-top") }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "CopyRightTopLink",
  mounted() {
    // Set a variable for our button element.
    const scrollToTopButton = document.getElementById("js-top");

    // Let's set up a function that shows our scroll-to-top button if we scroll beyond the height of the initial window.
    const scrollFunc = () => {
      // Get the current scroll value
      let y = window.scrollY;

      // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
      if (y > 0) {
        scrollToTopButton.className = "top-link show";
      } else {
        scrollToTopButton.className = "top-link hide";
      }
    };

    window.addEventListener("scroll", scrollFunc);

    const scrollToTop = () => {
      // Let's set a variable for the number of pixels we are from the top of the document.
      const c = document.documentElement.scrollTop || document.body.scrollTop;

      // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
      // We'll also animate that scroll with requestAnimationFrame:
      // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        // ScrollTo takes an x and a y coordinate.
        // Increase the '10' value to get a smoother/slower scroll!
        window.scrollTo(0, c - c / 10);
      }
    };

    // When the button is clicked, run our ScrolltoTop function above!
    scrollToTopButton.onclick = function (e) {
      e.preventDefault();
      scrollToTop();
    };
  },
};
</script>

<style scoped lang="scss">
#copyright {
  color: #193c65;
  padding: 3rem;
  text-align: center;
}
.top-link {
  transition: all 0.25s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  border-radius: 50%;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  background-color: #76a157;
  border: 1px solid white;
}

.top-link:hover {
  border: 1px solid #76a157;
}

.top-link.show {
  visibility: visible;
  opacity: 1;
}

.top-link.hide {
  visibility: hidden;
  opacity: 0;
}

.top-link svg {
  fill: #fff;
  width: 24px;
  height: 12px;
}

.top-link:hover {
  background-color: #e8e8e8;
}

.top-link:hover svg {
  fill: #76a157;
}
</style>
