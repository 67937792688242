<template>
  <header class="header-bar">
    <nav id="navbar" class="spacing">
      <div class="logo">
        <router-link :to="`/${$i18n.locale}`">
          <img src="../assets/logo.png" :alt="$t('copyright')" />
        </router-link>
      </div>
      <div class="links">
        <router-link :to="`/${$i18n.locale}`" class="nav-link house">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              fill="white"
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>
        </router-link>
        <router-link
          class="nav-link"
          v-for="(links, index) in menuItems[$i18n.locale]"
          :key="index"
          :to="`/${$i18n.locale}/${links.link}`"
        >
          {{ links.name }}
        </router-link>
        <language-toggle class="nav-link" />
      </div>
    </nav>

    <!-- <div id="logo">
        <a href=".">
          <img
            src="img/v1_horizontal_white.png"
            alt="Campus Biodiversity Network logo"
          />
        </a>
      </div>
      <div id="menu">
        <div class="hamburger">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <ul>
          <li class="hamburger-close">
            <div class="line"></div>
            <div class="line"></div>
          </li>
          <li v-for="(item, index) in menuItems" :key="index">
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div> -->
    <!-- <div class="banner"> -->
    <!-- <img id="bee" src="img/banner.jpeg"> -->
    <!-- <p id="discover">Institutions</p> -->
    <!-- </div> -->
    <slot></slot>
  </header>
</template>

<script>
import LanguageToggle from "./LanguageToggle.vue";

export default {
  name: "NavBar",
  components: {
    LanguageToggle,
  },
  data() {
    return {
      nav: null,
      menuItems: {
        en: [
          { name: "Get Started", link: "getting-started" },
          { name: "Join", link: "join" },
          { name: "Projects", link: "projects" },
          { name: "Institutions", link: "institutions" },
          { name: "Mission", link: "mission" },
        ],
        fr: [
          { name: "Démarrer", link: "getting-started" },
          { name: "S'inscrire", link: "join" },
          { name: "Projets", link: "projects" },
          { name: "Institutions", link: "institutions" },
          { name: "Mission", link: "mission" },
        ],
      },
    };
  },
  mounted() {
    // this.nav = document.getElementById("navbar");
    // this.navLinks.forEach((link) => {
    //   let a = document.createElement("a");
    //   a.href = link.link;
    //   a.innerText = link.name;
    //   this.nav.appendChild(a);
    // });
  },
};
</script>

<style scoped lang="scss">
.header-bar {
  position: relative;
  &.home nav {
    position: absolute;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
#navbar {
  padding-bottom: 1rem;
  display: grid;
  gap: 1rem;
  justify-content: start;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
    grid-template-columns: 1fr 2fr;
  }
  // background-color: #193c65;
  .logo {
    padding: 1.5rem 1rem 0;
    align-self: center;
    img {
      width: 100%;
      max-width: 50vw;
    }
  }
  .links {
    padding-bottom: 1rem;
    align-self: center;
    justify-self: start;
    justify-content: right;
    text-align: right;
    font-weight: 200;
    font-size: 1.25em;
    display: grid;
    @media screen and (min-width: 768px) {
      justify-self: end;
      display: initial;
      padding-bottom: 0;
    }

    // @media screen and (min-width: 1024px) {
    //   padding-top: 1.5vh;
    // }
  }
  a.nav-link {
    // text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem;
    text-decoration: none;
    padding: 1rem 0.5rem;
    white-space: nowrap;
    // padding: 1rem;
    transition: all 0.3s ease-in-out;
    @media screen and (min-width: 1024px) {
      font-size: 1.25rem;
    }
    &.house {
      display: none;
      padding: 0 0 5px;
      margin-right: 0.75rem;
      border-bottom: 2px solid transparent;
      &:hover,
      &.router-link-active {
        border-bottom: 2px solid white;
      }
      svg {
        height: 1.1rem;
        width: 1.1rem;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 0.5em;
        }
      }
      @media screen and (min-width: 1200px) {
        display: initial;
      }
    }
    &.router-link-active,
    &:hover {
      // background-color: #fff;
      // color: #193c65;
      text-decoration: underline;
      text-underline-offset: 0.5em;
    }
  }
}
</style>
