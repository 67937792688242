<template>
  <div id="footer">
    <footer>
      <div class="spacing">
        <ContactDetails />
        <RelatedLinks />
        <INaturalistWidget />
      </div>
    </footer>
    <SupportLinks class="spacing" />
    <CopyrightTopLink class="spacing" />
  </div>
</template>

<script>
import ContactDetails from "./ContactDetails.vue";
import RelatedLinks from "./RelatedLinks.vue";
import INaturalistWidget from "./INaturalistWidget.vue";
import SupportLinks from "./SupportLinks.vue";
import CopyrightTopLink from "./CopyrightTopLink.vue";
export default {
  name: "FooterBar",
  components: {
    ContactDetails,
    RelatedLinks,
    INaturalistWidget,
    SupportLinks,
    CopyrightTopLink,
  },
};
</script>

<style lang="scss">
#footer {
  color: white;

  footer {
    padding: 3rem 0;
    text-align: left;
    background-color: #193c65;

    & .spacing {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 5vw;
      place-content: center;
      gap: 10vw;
    }

    .subtitle,
    a {
      color: white;
    }

    .subtitle {
      padding-top: 0;
      font-size: 2rem;
    }
  }

  .address {
    padding-bottom: 1rem;
  }

  #related-links ul {
    list-style-type: none;
    padding: 0;
  }

  #related-links a {
    line-height: 1.5rem;
  }

  #contact-details a:hover,
  #related-links a:hover {
    text-decoration: underline;
  }

  #related-links ul.footer-social {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 1rem;
    place-content: center;
    gap: 1rem;
    padding-top: 1rem;
    justify-items: left;
  }

  .footer-social a svg {
    fill: white;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  #footer {
    footer {
      & .spacing {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
</style>
