<template>
  <div id="observations">
    <h2 class="subtitle">{{ $t("footer.latest-observations") }}</h2>
    <div>
      <div class="observations"></div>
    </div>
    <div class="footnote">
      <a
        rel="nofollow"
        target="_blank"
        href="https://www.inaturalist.org/observations?project_id=102092"
        >{{ $t("footer.more-observations") }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "INaturalistWidget",
  async mounted() {
    let iNat = document.querySelector("#inaturalist");
    document.querySelector(".observations").appendChild(iNat);
    iNat.classList.remove("hidden");
  },
};
</script>

<style lang="scss">
#inaturalist.hidden {
  display: none;
}
div#observations h2.subtitle {
  font-size: 1.25rem;
  padding-bottom: 1rem;
}
#inaturalist {
  & #observations .subtitle {
    font-size: 1.25rem;
    padding-bottom: 1.75rem;
  }

  & .inat-widget-small {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-bottom: 0.75rem;
  }

  & .inat-observation-image img {
    width: 90%;
  }

  & .inat-observation-image:hover {
    opacity: 0.8;
  }

  .footnote {
    padding-top: 1rem;
  }
}
</style>
