<template>
  <a
    id="language-toggle"
    v-for="lang in otherLanguages"
    :key="lang"
    @click="toggleLanguage(lang)"
  >
    {{ lang }}
  </a>
</template>

<script>
// import { useLangStore } from "@/stores/lang";

export default {
  name: "LanguageToggle",
  // setup() {
  //   const lang = useLangStore();
  //   return { lang };
  // },
  data() {
    return {
      // lang: this.$store.state.lang,
      languages: ["en", "fr"],
    };
  },
  methods: {
    toggleLanguage(lang) {
      // this.lang.setCurrentLang(lang);
      this.$i18n.locale = lang;
      this.$router.push({ name: this.$route.name, params: { lang: lang } });
    },
  },
  computed: {
    otherLanguages() {
      // return this.lang.getOtherLanguages;
      return this.languages.filter((lang) => lang !== this.$route.params.lang);
    },
  },
};
</script>

<style scoped lang="scss">
#language-toggle {
  display: inline;
  text-transform: lowercase;
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-gap: 1rem; */
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  // padding: 1rem;
  width: 2rem;
  display: inline;
  text-align: center;
  transition: all 0.3s ease-in-out;
  justify-self: end;
  &:hover {
    // background-color: #fff;
    // color: #193c65;
    text-decoration: underline;
    text-underline-offset: 0.5em;
  }
}
</style>
