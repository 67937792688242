<template>
  <header class="header-bar-mobile">
    <div class="logo">
      <router-link :to="`/${$i18n.locale}`">
        <img src="../assets/logo.png" :alt="$t('copyright')" />
      </router-link>
      <!-- hamberger menu -->
      <div
        class="hamburger"
        v-show="!mobileNavOpen"
        @click="mobileNavOpen = !mobileNavOpen"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
          />
        </svg>
      </div>
    </div>
    <nav id="navbar-mobile" class="spacing" :class="{ mobileNavOpen }">
      <div class="links">
        <!-- close hamberger -->
        <div
          class="hamburger-close"
          v-show="mobileNavOpen"
          @click="mobileNavOpen = !mobileNavOpen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 384 512"
          >
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
          </svg>
        </div>
        <router-link
          :to="`/${$i18n.locale}`"
          @click="closeNav"
          class="nav-link house"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path
              fill="white"
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
            />
          </svg>
        </router-link>
        <router-link
          class="nav-link"
          @click="closeNav"
          v-for="(links, index) in menuItems[$i18n.locale]"
          :key="index"
          :to="`/${$i18n.locale}/${links.link}`"
        >
          {{ links.name }}
        </router-link>

        <a
          id="language-toggle"
          v-for="lang in otherLanguages"
          :key="lang"
          @click="toggleLanguage(lang)"
          class="nav-link language-toggle"
        >
          {{ lang }}
        </a>
        <!-- <language-toggle
          @click="mobileNavOpen = !mobileNavOpen"
          class="nav-link language-toggle"
        /> -->
      </div>
    </nav>
    <slot></slot>
  </header>
</template>

<script>
// import LanguageToggle from "./LanguageToggle.vue";

export default {
  name: "NavBar",
  components: {
    // LanguageToggle,
  },
  data() {
    return {
      nav: null,
      mobileNavOpen: false,
      menuItems: {
        en: [
          { name: "Get Started", link: "getting-started" },
          { name: "Join", link: "join" },
          { name: "Projects", link: "projects" },
          { name: "Institutions", link: "institutions" },
          { name: "Mission", link: "mission" },
        ],
        fr: [
          { name: "Démarrer", link: "getting-started" },
          { name: "S'inscrire", link: "join" },
          { name: "Projets", link: "projects" },
          { name: "Institutions", link: "institutions" },
          { name: "Mission", link: "mission" },
        ],
      },
    };
  },
  methods: {
    closeNav() {
      this.mobileNavOpen = false;
    },
    toggleLanguage(lang) {
      // this.lang.setCurrentLang(lang);
      this.mobileNavOpen = !this.mobileNavOpen;
      this.$i18n.locale = lang;
      this.$router.push({ name: this.$route.name, params: { lang: lang } });
    },
  },
  mounted() {},
  computed: {
    languages() {
      return this.$i18n.availableLocales;
    },
    otherLanguages() {
      // return this.lang.getOtherLanguages;
      return this.languages.filter((lang) => lang !== this.$route.params.lang);
    },
  },
};
</script>

<style scoped lang="scss">
.header-bar-mobile {
  position: relative;
  padding-bottom: 5rem;

  &.home nav {
    position: absolute;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.banner-home #discover {
  font-size: 3rem;
}

#navbar-mobile {
  // full screen mobile nav with slide in
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #193c65;
  z-index: 1000;
  transform: translateX(200%);
  transition: transform 0.3s ease-in-out;

  display: grid;
  gap: 1rem;
  justify-content: space-between;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }

  // background-color: #193c65;
  &.mobileNavOpen {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;

    .links {
      display: grid;
    }
  }

  .links {
    height: 100%;
    align-self: center;
    justify-content: right;
    text-align: right;
    font-weight: 200;
    font-size: 1.25em;
    display: none;

    @media screen and (min-width: 768px) {
      display: initial;
    }

    // @media screen and (min-width: 1024px) {
    //   padding-top: 1.5vh;
    // }
  }

  a.nav-link {
    // text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-weight: 300;
    font-size: 2rem;
    text-decoration: none;
    padding: 1rem 3rem 1rem 0.5rem;
    justify-items: center;
    white-space: nowrap;
    // padding: 1rem;
    transition: all 0.3s ease-in-out;

    &.house {
      svg {
        height: 1.1rem;
        width: 1.1rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid transparent;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 0.5em;
        }
      }
    }
    &.language-toggle {
      margin-bottom: 10rem;
    }

    &.router-link-active,
    &:hover {
      // background-color: #fff;
      // color: #193c65;
      text-decoration: underline;
      text-underline-offset: 0.5em;
      &.house {
        svg {
          border-bottom: 2px solid white;
        }
      }
    }
  }
}

.logo {
  position: absolute;
  // padding: 1.5rem 1rem;
  width: 100%;
  align-self: center;

  // background-color: #193c65;
  img {
    width: 100%;
    max-width: 50vw;
    padding: 1.25rem;
  }
}

.hamburger {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1001;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: white;
  }
}

.hamburger-close {
  // position: absolute;
  // top: 1.5rem;
  // right: 1rem;
  justify-self: end;
  margin: 1.5rem;
  z-index: 1001;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: white;
  }
}
</style>
