<template>
  <div class="home">
    <div class="row split spacing">
      <div class="column">
        <figure>
          <img
            class="imgcolumn"
            src="../assets/landing/red-shouldered-hawk.jpg"
          />
          <figcaption>
            <a
              href="https://inaturalist.ca/observations/128906080"
              target="_blank"
            >
              <img src="../assets/cc.svg" alt="CC" /> D. Potter</a
            >
          </figcaption>
        </figure>
        <h3 class="subtitle">{{ $t("home.mission.title") }}</h3>
        <p class="text">
          {{ $t("home.mission.content") }}
        </p>
        <RouterLink :to="$i18n.locale + '/mission'" class="button">{{
          $t("home.mission.action")
        }}</RouterLink>
      </div>
      <div class="column">
        <figure>
          <img class="imgcolumn" src="../assets/landing/bug.jpg" />
          <figcaption>
            <a
              href="https://inaturalist.ca/observations/130017756"
              target="_blank"
            >
              <img src="../assets/cc.svg" alt="CC" />
              D. Potter</a
            >
          </figcaption>
        </figure>
        <h3 class="subtitle">{{ $t("home.projects.title") }}</h3>
        <p class="text">
          {{ $t("home.projects.content") }}
        </p>
        <RouterLink :to="$i18n.locale + '/projects'" class="button">{{
          $t("home.projects.action")
        }}</RouterLink>
      </div>
    </div>

    <div class="row single">
      <div class="column">
        <figure>
          <img class="imgcolumn" src="../assets/landing/banner2.jpg" />
          <figcaption>
            <a
              href="https://inaturalist.ca/observations/135463340"
              target="_blank"
            >
              <img src="../assets/cc.svg" alt="CC" /> Krishna Prasad Dhimal</a
            >
          </figcaption>
        </figure>
      </div>
      <div class="column spacing spacing-right">
        <h3 class="subtitle">{{ $t("home.institutions.title") }}</h3>
        <p class="text">
          {{ $t("home.institutions.content") }}
        </p>
        <RouterLink :to="$i18n.locale + '/institutions'" class="button">{{
          $t("home.institutions.action")
        }}</RouterLink>
      </div>
    </div>

    <div class="row single right">
      <div class="column">
        <figure>
          <img class="imgcolumn" src="../assets/landing/bear.jpg" />
          <figcaption>
            <a
              href="https://inaturalist.ca/observations/145447063"
              target="_blank"
            >
              <img src="../assets/cc.svg" alt="CC" /> Defina</a
            >
          </figcaption>
        </figure>
      </div>
      <div class="column spacing spacing-left">
        <h3 class="subtitle">{{ $t("home.get-started.title") }}</h3>
        <p class="text">
          {{ $t("home.get-started.content") }}
        </p>
        <RouterLink :to="$i18n.locale + '/getting-started'" class="button">{{
          $t("home.get-started.action")
        }}</RouterLink>
      </div>
    </div>

    <div class="row">
      <CarouselWidget class="spacing" />
    </div>

    <div class="row full-banner">
      <div>
        <h3 class="subtitle">{{ $t("home.join.title") }}</h3>
        <RouterLink :to="$i18n.locale + '/join'" class="button">{{
          $t("home.join.action")
        }}</RouterLink>
      </div>
    </div>

    <!-- <div class="row full">
        <h3 class="subtitle">Commonly Asked Questions</h3>
        <div id="qa">
            <div class="qa">
                <h4>For Administrators</h4>
            </div>
            <div class="qa">
                <h4>For Academics</h3>
            </div>
            <div class="qa">
                <h4>For Students</h3>
            </div>
        </div>
    </div> -->

    <div class="row full spacing">
      <div id="about">
        <h3 class="subtitle">{{ $t("home.about.title") }}</h3>
        <p class="about">
          {{ $t("home.about.content") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CarouselWidget from "@/components/CarouselWidget.vue";

export default {
  name: "HomeView",
  components: {
    CarouselWidget,
  },
};
</script>

<style scoped lang="scss">
// .row {
//   padding-top: 0;
//   padding-bottom: 0;
//   margin-top: 10vh;
//   margin-bottom: 10vh;
// }
.subtitle {
  /* padding-top: 15%; */
  font-weight: 700;
  font-size: 3.25rem;
  color: #193c65;
  padding: 2rem 0 1rem;
}
.text {
  font-weight: 200;
  /* font-size: 1.5em; */
  font-size: 1.25rem;
  color: #193c65;
}

.row {
  margin-bottom: 5rem;
}

.row.split {
  grid-template-columns: 1fr;
  display: grid;
  grid-column-gap: 5vw;
  place-content: center;
  gap: 10vw;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.row.single {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  place-content: center;
  background-color: #193c65;
  align-items: center;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10vw;
  }
  .subtitle {
    color: white;
  }
  .text {
    font-weight: 200;
    /* font-size: 1.5em; */
    font-size: 1.25rem;
    color: white;
  }

  &.right .column:first-child {
    @media screen and (min-width: 768px) {
      order: 2;
    }
  }
}

.row.full-banner {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~@/assets/landing/girltakingpicture.jpg");
  display: block;
  background-position: center;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;

  .subtitle {
    color: white;
  }
}
.imgcolumn {
  width: 100%;
  aspect-ratio: 4/3;
}

#about {
  padding-top: 3rem;
  h3 {
    text-align: center;
  }
  p {
    font-weight: 300;
    font-size: 1.25rem;
    max-width: 35rem;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
