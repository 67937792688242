<template>
  <nav-bar-mobile :class="$route.name">
    <div v-if="$route.name === 'home'" class="banner-home">
      <!-- <img id="bee" src="img/banner.jpeg"> -->
      <h1 id="discover">{{ $t("home.discover.content") }}</h1>
    </div>
    <div v-else class="banner"></div>
  </nav-bar-mobile>
  <nav-bar :class="$route.name">
    <div v-if="$route.name === 'home'" class="banner-home">
      <!-- <img id="bee" src="img/banner.jpeg"> -->
      <h1 id="discover">{{ $t("home.discover.content") }}</h1>
    </div>
    <div v-else class="banner"></div>
  </nav-bar>
  <main>
    <router-view />
  </main>
  <footer-bar />
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";
import NavBarMobile from "./components/NavBarMobile.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
    NavBarMobile,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.$i18n.locale = to.params.lang;
        document.title =
          to.meta.title ||
          this.$t("copyright.title") + " - " + this.$t("pages." + to.name);
      },
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
}

figure {
  position: relative;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  figcaption {
    font-size: 0.75rem;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    background-color: #193c65;
    padding: 0.25rem;
    a {
      color: white;
      text-decoration: none;
      display: grid;
      grid-template-columns: auto 1fr;
      img {
        display: inline;
        width: 1rem;
        height: 1rem;
        fill: white;
        padding-right: 0.25rem;
      }
    }
  }
}

.spacing {
  margin: 0 5vw;

  @media screen and (min-width: 768px) {
    margin: 0 10vw;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 10vw;
  }

  @media screen and (min-width: 1280px) {
    margin: 0 10vw;
  }

  @media screen and (min-width: 1600px) {
    margin: 0 15vw;
  }

  @media screen and (min-width: 1920px) {
    margin: 0 20vw;
  }

  &.spacing-left {
    @media screen and (min-width: 768px) {
      margin-right: 0;
    }
  }

  &.spacing-right {
    @media screen and (min-width: 768px) {
      margin-left: 0;
    }
  }
}

#app {
  font-family: "Inter", sans-serif;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #193c65;
}

nav {
  // padding: 30px;
  a {
    font-weight: bold;
    color: #193c65;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.banner {
  background-color: #193c65;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.banner-home {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~@/assets/landing/banner.jpg");
  display: block;
  background-position: center;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #222552;
  #discover {
    text-align: center;
    padding: 0 10vw;
    color: white;
    /* font-size: 72px; */
    font-size: 3rem;
    font-weight: 700;
    max-width: 60rem;
    padding: 1em 0;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin: 0 10vw;
      font-size: 3rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 4rem;
    }
    @media screen and (min-width: 1280px) {
      font-size: 5rem;
    }
  }
}

main {
  padding: 5rem 0;
}

.screen-reader-text {
  position: absolute;
  clip-path: inset(50%);
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.screen-reader-text:focus {
  display: block;
  top: 5px;
  left: 5px;
  z-index: 100000;
  clip-path: none;
  background-color: #eee;
  padding: 15px 23px 14px;
  width: auto;
  height: auto;
  text-decoration: none;
  line-height: normal;
  color: #444;
  font-size: 1em;
  clip: auto !important;
}

.button {
  color: #ffffff;
  background-color: #76a157;
  font-weight: 400;
  font-size: 1.25em;
  padding: 0.5em;
  border-radius: 0.25em;
  text-decoration: none;
  line-height: 5em;
  border: 1px solid #76a157;
}

// // Import everything here

// :root {
//   --margin-spacing: 20vw;
// }

// * {
//   margin: 0;
//   padding: 0;
// }

// body {
//   font-family: "Inter", sans-serif;
// }

// /* add css for fade in when visible */

// .fade-in {
//   opacity: 0;
//   transform: translateY(2rem);
// }

// .fade-in.visible {
//   opacity: 1;
//   transform: translateY(0);
//   transition: opacity 1s ease-in-out 0.1s, transform 1s ease-in-out 0.1s;
// }

// a {
//   color: white;
//   background-color: none;
//   text-decoration: none;
// }

// #navbar {
//   /* padding: 2rem; */
// }

// #navbar {
//   position: absolute;
//   z-index: 1;
//   display: grid;
//   grid-template-columns: 4fr 1fr;
// }

// #logo {
//   padding: 1rem;
//   align-self: center;
// }

// #logo img {
//   width: 100%;
// }

// #menu {
//   text-align: right;
//   font-weight: 200;
//   font-size: 1.25em;
// }

// .hamburger {
//   display: block;
//   text-align: right;
//   padding-right: 2rem;
//   padding-top: 1.25rem;
//   width: 40px;
//   height: 40px;
//   position: relative;
//   cursor: pointer;
//   margin-left: auto;
// }

// .line {
//   width: 40px;
//   height: 3px;
//   background-color: white;
//   margin: 10px 0;
//   transition: 0.4s;
//   pointer-events: none;
// }

// .hamburger-close .line {
//   background-color: #193c65;
// }

// .hamburger-close .line:nth-child(1) {
//   transform: rotate(-45deg) translate(-10px, 10px);
// }

// .hamburger-close .line:nth-child(2) {
//   transform: rotate(45deg);
// }

// nav.nav-open #menu ul {
//   z-index: 2;
//   background-color: rgba(255, 255, 255);
//   color: #193c65;
//   display: grid;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 90vh;
//   padding-bottom: 10vh;
//   text-align: center;
//   align-items: center;
//   justify-items: center;
// }

// nav.nav-open #menu ul a {
//   font-size: 1.5rem;
//   font-weight: 500;
//   color: #193c65;
// }

// #menu ul {
//   display: none;
//   list-style-type: none;
// }

// #menu li {
//   display: inline-block;
//   text-decoration: none;
//   padding: 1rem 0.5rem;
//   white-space: nowrap;
// }

// #menu a:hover {
//   text-decoration: underline;
//   text-underline-offset: 0.5em;
// }

// // .banner {
// //   width: 100%;
// //   // height: 100vh;
// //   height: 5em;
// //   background-repeat: no-repeat;
// //   background-size: cover;
// //   // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
// //   //   url(../img/banner.jpeg);
// //   display: block;
// //   background-position: center;
// //   position: relative;
// //   display: grid;
// //   align-items: center;
// //   justify-items: center;
// //   background-color: #222552;
// // }

// .pages .banner {
//   height: 5.5rem;
//   min-height: 5.5rem;
//   background-image: none;
// }

// #discover {
//   text-align: center;
//   margin: 0 10vw;
//   color: white;
//   /* font-size: 72px; */
//   font-size: 3rem;
//   font-weight: 700;
//   padding-top: 1rem;
//   padding-bottom: 1.5rem;
// }

// .button {
//   color: #ffffff;
//   background-color: #76a157;
//   font-weight: 400;
//   font-size: 1.25em;
//   padding: 0.5em;
//   border-radius: 0.25em;
//   text-decoration: none;
//   line-height: 5em;
//   border: 1px solid #76a157;
// }

// a.button:hover {
//   color: #76a157;
//   background-color: white;
//   border: 1px solid #76a157;
// }

// .row,
// .row.single {
//   /* padding-left: 3rem;
//   padding-right: 3rem; */
//   padding-top: 0;
//   padding-bottom: 0;
//   margin-top: 10vh;
//   margin-bottom: 10vh;
// }

// .row {
//   display: grid;
// }

// .imgcolumn {
//   width: 100%;
//   aspect-ratio: 4/3;
// }

// figure {
//   position: relative;
// }

// figcaption {
//   position: absolute;
//   bottom: 0.5rem;
//   right: 0.5rem;
//   font-size: 0.7rem;
//   color: white;
// }

// .subtitle {
//   font-weight: 700;
//   font-size: 3.25rem;
//   color: #193c65;
//   padding: 2rem 0 1rem;
// }

// .text {
//   font-weight: 200;
//   /* font-size: 1.5em; */
//   font-size: 1.25rem;
//   color: #193c65;
// }

// .row.split {
//   grid-template-columns: 1fr;
//   grid-column-gap: 5vw;
//   place-content: center;
//   margin-left: 5vw;
//   margin-right: 5vw;
//   gap: 10vw;
// }

// .row.single {
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-column-gap: 0;
//   place-content: center;
//   background-color: #193c65;
//   align-items: center;
// }

// .row.single .column:first-child {
//   /* padding-left: 20%; */
//   margin-left: 5vw;
//   margin-right: 5vw;
// }

// .row.single .subtitle,
// .row.banner .subtitle {
//   color: white;
//   /* padding-top: 15%; */
// }

// .row.single .text {
//   color: white;
// }

// .row.single .imgcolumn {
//   text-align: right;
// }

// .row.single .column:first-child {
//   order: 2;
// }

// .row.single.right {
//   grid-template-columns: 1fr;
// }

// .row.single.right .column:first-child {
//   order: 2;
// }

// .row.single.right .imgcolumn {
//   text-align: left;
// }

// .row.full {
//   text-align: center;
//   margin-left: 5vw;
//   margin-right: 5vw;
// }

// .row.full p {
//   max-width: 30em;
//   margin: 0 auto;
// }

// .pages .row.full {
//   margin-top: 0;
// }

// .row.full-banner {
//   padding: 0;
//   margin: 0;
//   width: 100%;
//   height: 60vh;
//   background-repeat: no-repeat;
//   background-size: cover;
//   // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//   //   url(../img/girltakingpicture.webp);
//   display: block;
//   background-position: center;
//   position: relative;
//   display: grid;
//   align-items: center;
//   justify-items: center;
//   text-align: center;
// }

// .row.full-banner .subtitle {
//   color: white;
// }

// /*
// #smallbanner {
//   width: 100%;
//   height: 75vh;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//     url(../img/girltakingpicture.webp);
//   display: block;
//   background-position: center;
//   position: relative;
//   margin-top: 10%;
// } */

// #qa {
//   display: grid;
//   grid-template-columns: 1fr;
// }

// .qa {
//   border: 3px solid #76a157;
//   border-radius: 2rem;
//   padding: 0.5rem;
//   padding-left: 2em;
//   margin: 0.5rem;
//   text-align: left;
// }

// .about {
//   font-weight: 300;
//   font-size: 1.25rem;
//   color: #222552;
// }

// footer {
//   padding: 3rem;
//   padding-left: 5vw;
//   padding-right: 5vw;
//   background-color: #193c65;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-column-gap: 5vw;
//   place-content: center;
//   gap: 10vw;
// }

// footer,
// footer .subtitle,
// footer a {
//   color: white;
// }

// footer .subtitle {
//   padding-top: 0;
//   font-size: 2rem;
// }

// .address {
//   padding-bottom: 1rem;
// }

// #about-more {
//   /* text-align: right; */
// }

// #about-more ul {
//   list-style-type: none;
//   padding: 0;
// }

// #about-more a {
//   line-height: 1.5rem;
// }

// #contact-details a:hover,
// #about-more a:hover {
//   text-decoration: underline;
// }

// #inaturalist.hidden {
//   display: none;
// }

// #observations .subtitle {
//   font-size: 1.25rem;
//   padding-bottom: 1rem;
// }

// .inat-widget-small {
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
// }

// .inat-observation-image img {
//   width: 90%;
// }

// .inat-observation-image:hover {
//   opacity: 0.8;
// }

// .footnote {
//   padding-top: 1rem;
// }

// #about-more ul.footer-social {
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   grid-column-gap: 1rem;
//   place-content: center;
//   gap: 1rem;
//   padding-top: 1rem;
//   justify-items: left;
// }

// .footer-social a svg {
//   fill: white;
//   width: 100%;
// }

// #support div {
//   display: grid;
//   grid-template-columns: 1fr;
//   align-items: center;
//   justify-items: center;
//   padding-bottom: 1em;
//   gap: 3rem;
//   padding-top: 3rem;
// }

// #support div img {
//   width: 80%;
// }

// .top-link {
//   transition: all 0.25s ease-in-out;
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   display: inline-flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   margin: 0 1rem 1rem 0;
//   border-radius: 50%;
//   padding: 0.25rem;
//   width: 2rem;
//   height: 2rem;
//   background-color: #76a157;
//   border: 1px solid white;
// }

// .top-link:hover {
//   border: 1px solid #76a157;
// }

// .top-link.show {
//   visibility: visible;
//   opacity: 1;
// }

// .top-link.hide {
//   visibility: hidden;
//   opacity: 0;
// }

// .top-link svg {
//   fill: #fff;
//   width: 24px;
//   height: 12px;
// }

// .top-link:hover {
//   background-color: #e8e8e8;
// }

// .top-link:hover svg {
//   fill: #76a157;
// }

// .screen-reader-text {
//   position: absolute;
//   clip-path: inset(50%);
//   margin: -1px;
//   border: 0;
//   padding: 0;
//   width: 1px;
//   height: 1px;
//   overflow: hidden;
//   word-wrap: normal !important;
//   clip: rect(1px, 1px, 1px, 1px);
// }

// .screen-reader-text:focus {
//   display: block;
//   top: 5px;
//   left: 5px;
//   z-index: 100000;
//   clip-path: none;
//   background-color: #eee;
//   padding: 15px 23px 14px;
//   width: auto;
//   height: auto;
//   text-decoration: none;
//   line-height: normal;
//   color: #444;
//   font-size: 1em;
//   clip: auto !important;
// }

// /*
// #headless .team section {
//   display: grid;
//   grid-template-columns: 1fr;
// }

// #headless .team section .wp-block-media-text {
//   padding-bottom: 2rem;
//   display: grid;
//   grid-template-columns: 350px auto !important;
//   align-items: center;
//   min-height: 15rem;
// }

// #headless .team section .wp-block-media-text:nth-child(even) {
//   padding-bottom: 2rem;
//   display: grid;
//   grid-template-columns: auto 350px !important;
// }

// #headless .team section .wp-block-media-text:nth-child(even) .wp-block-media-text__media {
//   order: 2;
//   justify-self: right;
// }

// #headless .team section .wp-block-media-text__content p {
//   padding: 0;
//   margin: 0;
// }

// #headless .team section .wp-block-media-text:nth-child(odd) .wp-block-media-text__content p {
//   margin-left: auto;
// }

// #headless .team section .wp-block-media-text__media {
//   margin: 0;
//   padding: 0;
// }

// #headless .team section .wp-block-media-text__media img {
//   max-height: 20rem;
//   width: auto;
// } */

// /*
// #headless .team section {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 5rem;
// }

// #headless .team section .wp-block-media-text:nth-child(even) {
//   grid-template-columns: 350px auto !important;
// }

// #headless .team section .wp-block-media-text:nth-child(even) .wp-block-media-text__media {
//   order: 0;
//   justify-self: left;
// } */

// /* generate mobile first responsive media query breakpoints boilerplate */

// @media screen and (min-width: 320px) {
//   /* styles for viewports larger than 320px */
// }

// @media screen and (min-width: 480px) {
//   /* styles for viewports larger than 480px */
// }

// @media screen and (min-width: 600px) {
//   /* styles for viewports larger than 600px */

//   #navbar {
//     grid-template-columns: 1fr 1fr;
//     justify-content: space-between;
//   }
// }

// @media screen and (min-width: 768px) {
//   /* styles for viewports larger than 768px */

//   .pages .banner {
//     height: 10vh;
//   }

//   #discover {
//     margin: 0 20vw;
//   }

//   .row.single {
//     grid-template-columns: 1fr 1fr;
//     margin-left: 0;
//     margin-right: 0;
//     gap: 10vw;
//   }

//   .row.single.right {
//     grid-template-columns: 1fr 1fr;
//   }

//   .row.split {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   .row.single .column:first-child {
//     order: 0;
//   }

//   footer {
//     grid-template-columns: repeat(3, 1fr);
//   }

//   #support div {
//     grid-template-columns: repeat(2, 1fr);
//     gap: 0;
//   }

//   #navbar,
//   .row,
//   .row.split,
//   .row.full {
//     margin-left: 2rem;
//     margin-right: 2rem;
//   }

//   footer {
//     padding-left: 2rem;
//     padding-right: 2rem;
//   }

//   .row.single,
//   footer,
//   .row.single .column:first-child {
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .row.single .column:first-child {
//     padding-left: 2rem;
//     padding-right: 0;
//   }

//   .row.single.right .column:first-child {
//     padding-left: 0;
//     padding-right: 2rem;
//   }

//   .row.single {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

// @media screen and (min-width: 1024px) {
//   /* styles for viewports larger than 1024px */
//   #navbar,
//   .pages .banner {
//     // height: 8vh;
//   }

//   #navbar {
//     grid-template-columns: 1fr 2fr;
//   }

//   #menu {
//     padding-top: 1.5vh;
//   }

//   .hamburger {
//     display: none;
//   }

//   #menu ul .hamburger-close {
//     display: none;
//   }

//   #menu ul {
//     display: grid;
//   }

//   #menu ul {
//     display: initial;
//   }

//   #menu li {
//     display: inline-block;
//   }

//   #discover {
//     font-size: 4.5rem;
//   }

//   #navbar,
//   .row,
//   .row.split,
//   .row.full {
//     margin-left: 10vw;
//     margin-right: 10vw;
//   }

//   .row.single,
//   footer {
//     margin-left: 0;
//     margin-right: 0;
//     padding-left: 10vw;
//     padding-right: 10vw;
//   }

//   .row.single .column:first-child {
//     padding-left: 10vw;
//     padding-right: 0;
//   }

//   .row.single.right .column:first-child {
//     padding-left: 0;
//     padding-right: 10vw;
//   }

//   .row.single {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   #headless article.row > h2 {
//     font-size: 3rem;
//   }
// }

// @media screen and (min-width: 1280px) {
//   /* styles for viewports larger than 1280px */

//   #support div {
//     grid-template-columns: repeat(4, 1fr);
//   }

//   /* #navbar,
//   .row,
//   .row.split,
//   .row.full {
//     margin-left: 15vw;
//     margin-right: 15vw;
//   }

//   .row.single,
//   footer {
//     margin-left: 0;
//     margin-right: 0;
//     padding-left: 15vw;
//     padding-right: 15vw;
//   }

//   .row.single .column:first-child {
//     padding-left: 15vw;
//     padding-right: 0;
//   }

//   .row.single.right .column:first-child {
//     padding-left: 0;
//     padding-right: 15vw;
//   }

//   .row.single {
//     padding-left: 0;
//     padding-right: 0;
//   } */
// }

// @media screen and (min-width: 1280px) {
//   /* styles for viewports larger than 1280px */

//   /* #navbar,
//   .row,
//   .row.split,
//   .row.full {
//     margin-left: 20vw;
//     margin-right: 20vw;
//   }

//   .row.single,
//   footer {
//     margin-left: 0;
//     margin-right: 0;
//     padding-left: 20vw;
//     padding-right: 20vw;
//   }

//   .row.single .column:first-child {
//     padding-left: 20vw;
//     padding-right: 0;
//   }

//   .row.single.right .column:first-child {
//     padding-left: 0;
//     padding-right: 20vw;
//   }

//   .row.single {
//     padding-left: 0;
//     padding-right: 0;
//   } */
// }

// @media screen and (min-width: 1600px) {
//   /* styles for viewports larger than 1280px */

//   #navbar,
//   .pages .banner {
//     // height: 10vh;
//   }

//   #navbar,
//   .row,
//   .row.split,
//   .row.full {
//     margin-left: 20vw;
//     margin-right: 20vw;
//   }

//   .row.single,
//   footer {
//     margin-left: 0;
//     margin-right: 0;
//     padding-left: 20vw;
//     padding-right: 20vw;
//   }

//   .row.single .column:first-child {
//     padding-left: 20vw;
//     padding-right: 0;
//   }

//   .row.single.right .column:first-child {
//     padding-left: 0;
//     padding-right: 20vw;
//   }

//   .row.single {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }
</style>
