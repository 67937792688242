<template>
  <div id="contact-details">
    <h2 class="subtitle">{{ $t("footer.contact") }}</h2>
    <div>
      <div class="address">
        <p>
          821 Avenue Sainte-Croix<br />
          Montreal, Quebec<br />
          H4L 3X9 Canada
        </p>
      </div>
      <div>
        <label for="email">{{ $t("footer.email") }}: </label>
        <a href="mailto:biodivcampus@gmail.com">biodivcampus@gmail.com</a>
      </div>
      <div>
        <label for="phone">{{ $t("footer.phone") }}: </label>
        <a href="tel:+1-514-744-7500"> +1-514-744-7500</a> x7214
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
};
</script>
